<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Name">
          <a-input size="large" class="w-100" :value="dataProgram.nama"
            @change="(e) => handleChange(e.target.value, 'nama')" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <!-- <a-form-item label="Type">
          <a-select mode="multiple" class="w-100" placeholder="Select Program Type" size="large" :value="dataProgram.type"
            @change="(e) => handleChange(e.target.value, 'type')">
            <a-select-option value="K-13">K-13</a-select-option>
            <a-select-option value="Kumer">Kumer</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-model-item ref="type" :colon="false" prop="type">
          <span slot="label">
            Type&nbsp;
            <!-- <a-tooltip title="Pilih type untuk peserta didik saat ini.">
              <a-icon type="question-circle-o" />
            </a-tooltip> -->
          </span>
          <a-select class="w-100" placeholder="Select Program Type" id="type" size="large"
            @change="(e) => handleChange(e, 'type')">
            <a-select-option value="Kurikulum 2013">Kurikulum 2013</a-select-option>
            <a-select-option value="Kurikulum Merdeka">Kurikulum Merdeka</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataProgram: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style></style>
